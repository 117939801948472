<template>
  <div>
    <el-card class="card">
      <div slot="header" class="clearfix">
        <span class="title"> 添加快捷入口</span>
        <span class="checkedBox">
          全选
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          ></el-checkbox>
        </span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="cancel">关闭</el-button>
      </div>
      <div class="collegeMenuWrap">
        <div v-for="item in menuList" :key="item.menuId" class="collegeMenu">
          <div
            class="menuL"
            :title="item.menuName"
            :class="{ active: currentId == item.menuId }"
            @click="selectClass(item)"
          >
            {{ item.menuName }}
          </div>
          <div class="menuR">
            <el-checkbox-group
              v-for="(citem, cindex) in item.children"
              :key="cindex"
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox v-if="currentId == citem.parentId" :label="citem.menuName">
                {{ citem.menuName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="space"></div>
        </div>
      </div>
      <div class="dialog_footer">
        <el-button type="primary" @click="addSubmit">确定添加</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getUserMenu } from '@/api/college'
import to from 'await-to'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      checkedCities: [],
      list: [
        {
          id: 1,
          title: '学堂设置',
        },
        {
          id: 2,
          title: '学员管理',
        },
        {
          id: 3,
          title: '课程管理',
        },
        {
          id: 4,
          title: '班级管理',
        },
        {
          id: 5,
          title: '讲师管理',
        },
        {
          id: 6,
          title: '考试管理',
        },
        {
          id: 7,
          title: '财务管理',
        },
      ],
      status: [],
      content: [
        {
          infoID: 1,
          info: [
            { name: '学堂设置', path: '/college/collegeSetting' },
            { name: '公告管理', path: '/college/collegeNotice' },
            { name: '账户管理', path: '/college/accountsManage' },
          ],
        },
        {
          infoID: 2,
          info: [{ name: '学员管理', path: '/student/list' }, { name: '部门管理' }],
        },
        {
          infoID: 3,
          info: [
            { name: '课程资源管理', path: '/course/resources' },
            { name: '学堂课程库', path: '/course/library' },
          ],
        },
      ],
      currentId: '1',
      menuList: [],
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.getMenuData()
  },
  methods: {
    async getMenuData() {
      const [res, err] = await to(getUserMenu({ clientCode: this.clientCode }))
      if (err) return this.$message.warning(err.msg)
      this.menuList = res.data
      this.currentId = this.menuList[0].menuId
      if (
        localStorage.getItem(
          this.userInfo.phone + 'quickEntrance' + '0a983d86-2527-5512-01a9-783cd0e1',
        )
      ) {
        this.status = JSON.parse(
          localStorage.getItem(
            this.userInfo.phone + 'quickEntrance' + '0a983d86-2527-5512-01a9-783cd0e1',
          ),
        )
      }
      let checkedArr = []
      if (this.status) {
        this.status.map(v => {
          checkedArr.push(v.name)
        })
        this.checkedCities = checkedArr
        if (checkedArr.length == this.select().length) {
          this.checkAll = true
        } else {
          this.isIndeterminate = true
        }
      }
    },
    select() {
      let arr = []
      this.menuList.map(v => {
        if (v.children && v.children.length !== 0) {
          v.children.map(c => {
            arr.push(c.menuName)
          })
        }
      })
      return arr
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false
      this.checkAll = val
      if (val) {
        this.checkedCities = this.select()
      } else {
        this.checkedCities = []
      }
    },
    selectClass(item) {
      this.currentId = item.menuId
    },
    handleCheckedCitiesChange(val) {
      let checkedCount = val.length
      this.checkAll = checkedCount === this.select().length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.select().length
    },
    addSubmit() {
      if (this.checkedCities.length == 0)
        return this.$message({ type: 'warning', message: '请选择要添加的快接入口' })
      let quickEntrance = []
      this.menuList.map(v => {
        if (v.children && v.children.length !== 0) {
          v.children.map(c => {
            this.checkedCities.map(i => {
              if (c.menuName == i) {
                quickEntrance.push({ name: c.menuName, path: v.path + '/' + c.path })
              }
            })
          })
        }
      })
      localStorage.setItem(
        this.userInfo.phone + 'quickEntrance' + this.clientCode,
        JSON.stringify(quickEntrance),
      )
      this.cancel()
    },
    cancel() {
      this.$router.push('/dashbord')
    },
  },
}
</script>

<style scoped lang="scss">
.checkedBox {
  margin-left: 10px;
  font-size: 14px;
}
.quickWrap {
  display: flex;
  .collegeList {
    height: 520px;
    width: 150px;
    padding-right: 10px;
    li {
      box-sizing: border-box;
      cursor: pointer;
      line-height: 50px;
      width: 150px;
      padding: 0 20px;
      margin-bottom: 5px;
      border-radius: 4px;
      @extend %one-line-text;
      &:hover {
        background-color: #fff2eb;
      }
      &.active {
        background-color: #ff7b33;
        color: #fff;
      }
    }
  }
}
.dialog_footer {
  text-align: center;
}
.collegeMenuWrap {
  height: 520px;
  position: relative;
  .collegeMenu {
    .menuL {
      box-sizing: border-box;
      cursor: pointer;
      line-height: 50px;
      width: 150px;
      padding: 0 20px;
      margin-bottom: 5px;
      border-radius: 4px;
    }
  }
  .menuR {
    position: absolute;
    left: 160px;
    top: 15px;
    display: flex;
    div {
      margin: 0 15px;
    }
  }
  .space {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 150px;
    border-right: 1px dashed #e2e2e2;
  }
}
.active {
  background-color: #ff7b33 !important;
  color: #fff !important;
}
</style>
